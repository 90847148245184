/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind, react/no-array-index-key, consistent-return */
/* eslint-disable react/no-unstable-nested-components, jsx-a11y/no-static-element-interactions */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import i18n from 'i18next';
import { GetOutputAPI, GetRedOutputAPI, sendNotification } from '../../API/api';
import ColoredBar from '../../components/ColoredBar/ColoredBar';
import houseIcon from '../../assets/designSystem/icons/output/house.svg';
import hospitalIcon from '../../assets/designSystem/icons/output/hospital.svg';
import warningIcon from '../../assets/designSystem/icons/output/warning-2.svg';
import dangerIcon from '../../assets/designSystem/icons/output/danger.svg';
import logoPic from '../../assets/designSystem/logo/symbol-default.svg';
import phone from '../../assets/designSystem/icons/icon_phone.png';
import './Output.css';
import OutputSection from '../../components/OutputSection/OutputSection';
import Button from '../../components/Button/Button';
import Loading from '../Loading/Loading';
import { returnDate } from '../../utils/index';
import Paracetamol from './PopUp/paracetamol';
import Fluids from './PopUp/fluids';
import MalariaAreas from './PopUp/malariaAreas';
import Ambulance from './PopUp/ambulance';
import BreathingQuickly from './PopUp/breathingQuickly';
import Eye from './PopUp/eye';
import ColdOrEar from './PopUp/coldOrEar';
import Cough from './PopUp/cough';
import Diarrhoea from './PopUp/diarrhoea';
import AbnormalMovement from './PopUp/abnormalMovement';
import Ibuprofen from './PopUp/ibuprofen';
import Ors from './PopUp/ors';
import PageNames from '../../utils/PageNames';
import PageTypes from '../../utils/PageTypes';
import MetricsContext from '../../context/MetricsContext';
import ButtonNames from '../../utils/ButtonNames';

function Output() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [outputNumber, setOutputNumber] = useState(0);
  const [outputSectionsArray, setoutputSectionsArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const params = location.state?.answers.split('.').slice(1).join('.');
  const symptom = location.state?.symptom;
  const ageGroup = location.state?.answers.split('.')[0][0];
  const babyOrChild = ageGroup < 4 ? 'baby' : 'child';
  const [showparacetamol, setShowparacetamol] = useState(false);
  const [showFluids, setShowFluids] = useState(false);
  const [showAmbulance, setShowAmbulance] = useState(false);
  const [showBreathing, setShowBreathing] = useState(false);
  const [showMalariaAreas, setShowMalariaAreas] = useState(false);
  const [showeye, setShoweye] = useState(false);
  const [showColdOrEar, setShowColdOrEar] = useState(false);
  const [showCough, setShowCough] = useState(false);
  const [showDiarrhoea, setShowDiarrhoea] = useState(false);
  const [showAbnormalMovement, setShowAbnormalMovement] = useState(false);
  const [showOrs, setShowOrs] = useState(false);
  const [showIbuprofen, setShowIbuprofen] = useState(false);
  const [completedJourneyId, setCompletedJourneyId] = useState('');
  const { language } = i18n;
  const { useUpdateUserMetric } = useContext(MetricsContext);
  const startDate = new Date();
  function showNotification(researchId, outputType) {
    navigator.serviceWorker.ready.then(registration => {
      registration.pushManager
        .getSubscription()
        .then(subscription => {
          const sub = {
            subscription,
            researchId,
            outputType,
            language,
          };
          sendNotification(sub);
        })
        .catch(err => {
          throw new Error(`Error during getSubscription(): ${err}`);
        });
    });
  }

  useEffect(() => {
    const userId = localStorage.getItem('sessionId');
    async function fetchOutput() {
      setLoading(true);
      if (location.state?.redAnswer === true) {
        const data = await GetRedOutputAPI(params, userId);
        const response = await data.json();
        if (!data.ok) {
          navigate('/error', {
            state: { code: data.status, message: JSON.stringify(response) },
          });
        } else {
          setOutputNumber(response.outputType);
          setoutputSectionsArray(response.outputSections);
          if (response.completedJourneyId) {
            showNotification(response.completedJourneyId, response.outputType);
            setCompletedJourneyId(response.completedJourneyId);
          }
        }
        setLoading(false);
      } else {
        const data = await GetOutputAPI(params, userId);
        const response = await data.json();

        if (!data.ok) {
          navigate('/error', {
            state: { code: data.status, message: JSON.stringify(response) },
          });
        } else {
          setOutputNumber(response.outputType);
          setoutputSectionsArray(response.outputSections);
          if (response.completedJourneyId) {
            showNotification(response.completedJourneyId, response.outputType);
            setCompletedJourneyId(response.completedJourneyId);
          }
        }
        setLoading(false);
      }
    }
    fetchOutput();
  }, []);
  useEffect(() => {
    useUpdateUserMetric(
      {
        name: PageNames.OUTPUT_PAGE,
        pageType: PageTypes.OUTPUT_METRIC,
        outputSectionsTotal: outputNumber,
        outputSectionsCount: outputSectionsArray.length,
      },
      {
        algorithmData: {
          outputsList: outputSectionsArray,
        },
      },
    );
  }, [outputNumber]);

  const outputs = [
    {
      color: 'output__green',
      icon: houseIcon,
      title: `Your ${babyOrChild} can stay at home`,
      description: `If your ${babyOrChild} worsens, does not improve or you get more worried, contact a Community Health Promoter (CHP) or visit a health center.`,
    },
    {
      color: 'output__yellow',
      icon: warningIcon,
      title: 'Monitor',
      description:
        'Watch out for any changes. Contact a Community Health Promoter (CHP) within the next 24 hours.',
    },
    {
      color: 'output__orange',
      icon: dangerIcon,
      title: 'Attention',
      description: `Take your ${babyOrChild} to the nearest health facility as soon as possible.`,
    },
    {
      color: 'output__red',
      icon: hospitalIcon,
      title: 'Emergency',
      description: `Your ${babyOrChild} may be very unwell. Take them to the nearest health facility NOW. Do not delay. If necessary, call an ambulance on 0800 721316(toll free)`,
    },
  ];
  const renderoutputitle = () => {
    switch (outputNumber) {
      case 0:
        return (
          <h3 className="output__header__title green">
            {t(`output.green.${babyOrChild}.title`)}
          </h3>
        );
      case 1:
        return (
          <h3 className="output__header__title monitor">
            {t(`output.title.${babyOrChild}.title`)}
          </h3>
        );
      case 2:
        return (
          <h3 className="output__header__title attention">
            {t('output.orange.title')}
          </h3>
        );
      case 3:
        return (
          <h3 className="output__header__title emergency">
            {t('output.red.title')}
          </h3>
        );
      default:
        break;
    }
  };

  const renderoutputp = () => {
    switch (outputNumber) {
      case 0:
        return (
          <p className="small">
            {t(`output.green.${babyOrChild}.description`)}
          </p>
        );
      case 1:
        return <p className="small">{t('output.yellow.description')}</p>;
      case 2:
        return (
          <p className="small">
            {t(`output.orange.${babyOrChild}.description`)}
          </p>
        );
      case 3:
        return (
          <p className="small">{t(`output.red.${babyOrChild}.description`)}</p>
        );
      default:
        break;
    }
  };

  const updateMetrics = actionName => {
    const dateFinal = new Date();
    useUpdateUserMetric({
      name: PageNames.OUTPUT_PAGE,
      pageType: PageTypes.OUTPUT_METRIC,
      duration: Math.abs(dateFinal - startDate),
      interactions: [
        {
          name: actionName,
          time: new Date(),
        },
      ],
    });
  };

  const handleNewAssessmentBtn = () => {
    updateMetrics(ButtonNames.CONTINUE_NEW);
    navigate('/disclaimer');
  };

  const handleContinueBtn = () => {
    updateMetrics(ButtonNames.CONTINUE);
    navigate('/feedback', {
      state: {
        completedJourneyId,
      },
    });
  };

  function handleRedirectParacetamol() {
    setShowparacetamol(!showparacetamol);
  }
  function handleRedirectFluids() {
    setShowFluids(!showFluids);
  }
  function handleRedirectAmbulance() {
    setShowAmbulance(!showAmbulance);
  }

  function handleRedirectBreathingQuickly() {
    setShowBreathing(!showBreathing);
  }

  function handleRedirectDiarrhoea() {
    setShowDiarrhoea(!showDiarrhoea);
  }

  function handleRedirectCough() {
    setShowCough(!showCough);
  }

  function handleRedirectColdorearsymptoms() {
    setShowColdOrEar(!showColdOrEar);
  }

  function handleRedirectAbnormalmovements() {
    setShowAbnormalMovement(!showAbnormalMovement);
  }

  function handleRedirectEyes() {
    setShoweye(!showeye);
  }

  function handleRedirectMalaria() {
    setShowMalariaAreas(!showMalariaAreas);
  }

  function handleRedirectOrs() {
    setShowOrs(!showOrs);
  }

  function handleRedirectIbuprofen() {
    setShowIbuprofen(!showIbuprofen);
  }

  function handlePopup(val) {
    updateMetrics(`Popup: ${val}`);
    switch (val) {
      case 'paracetamol':
        handleRedirectParacetamol();
        break;
      case 'fluids':
        handleRedirectFluids();
        break;
      case 'malaria':
        handleRedirectMalaria();
        break;
      case 'cough':
        handleRedirectCough();
        break;
      case 'coldorear':
        handleRedirectColdorearsymptoms();
        break;
      case 'diarrhoea':
        handleRedirectDiarrhoea();
        break;
      case 'ors':
        handleRedirectOrs();
        break;
      case 'eye':
        handleRedirectEyes();
        break;
      case 'breathingquickly':
        handleRedirectBreathingQuickly();
        break;
      case 'abnormal':
        handleRedirectAbnormalmovements();
        break;
      case 'ambulance':
        handleRedirectAmbulance();
        break;
      case 'ibuprofen':
        handleRedirectIbuprofen();
        break;
      default:
        break;
    }
  }

  return (
    <>
      {loading && <Loading />}
      {showparacetamol && (
        <Paracetamol
          babyOrChild={babyOrChild}
          callback={handleRedirectParacetamol}
        />
      )}
      {showFluids && <Fluids callback={handleRedirectFluids} />}
      {showAmbulance && <Ambulance callback={handleRedirectAmbulance} />}
      {showBreathing && (
        <BreathingQuickly
          babyOrChild={babyOrChild}
          callback={handleRedirectBreathingQuickly}
        />
      )}
      {showAbnormalMovement && (
        <AbnormalMovement
          babyOrChild={babyOrChild}
          callback={handleRedirectAbnormalmovements}
        />
      )}
      {showeye && (
        <Eye babyOrChild={babyOrChild} callback={handleRedirectEyes} />
      )}
      {showDiarrhoea && (
        <Diarrhoea
          babyOrChild={babyOrChild}
          callback={handleRedirectDiarrhoea}
        />
      )}
      {showColdOrEar && (
        <ColdOrEar
          babyOrChild={babyOrChild}
          callback={handleRedirectColdorearsymptoms}
        />
      )}
      {showCough && (
        <Cough babyOrChild={babyOrChild} callback={handleRedirectCough} />
      )}
      {showMalariaAreas && <MalariaAreas callback={handleRedirectMalaria} />}
      {showOrs && <Ors callback={handleRedirectOrs} />}
      {showIbuprofen && (
        <Ibuprofen
          babyOrChild={babyOrChild}
          callback={handleRedirectIbuprofen}
        />
      )}
      {!loading && (
        <div className="container">
          <div className="containerContent output__container">
            <div className={`output__header ${outputs[outputNumber].color}`}>
              <img
                src={outputs[outputNumber].icon}
                alt="icon"
                width="64"
                height="64"
              />
              {renderoutputitle()}
              <p className="small output__header__p">
                {' '}
                <span>
                  {returnDate()}
                  {' '}
                  -
                  {' '}
                </span>
                { }
                {symptom}
              </p>
              {renderoutputp()}
              {outputs[outputNumber].description === 3 && (
                <a href="tel:0800721316" className="phone__btn__button">
                  <span>
                    <img src={phone} alt="phone icon" />
                  </span>
                  <span>
                    <p className="phone__btn__number">
                      {t('popUps.ambulance.number1')}
                    </p>
                  </span>
                </a>
              )}
            </div>
            <div className="output__content">
              <div className="output__outputSections">
                {outputSectionsArray.map(answer => (
                  <OutputSection
                    // eslint-disable-next-line no-underscore-dangle
                    key={answer._id}
                    data={answer}
                    callback={handlePopup}
                  />
                ))}
              </div>
              <div className="output__card">
                <img src={logoPic} alt="icon" width="40" height="20" />
                <p className="output__card__p">
                  {t(`output.${babyOrChild}.outputcardp`)}
                </p>
                <Button
                  width="fullWidth"
                  hierarchy="secondary"
                  onClick={handleNewAssessmentBtn}
                >
                  {t('output.restart')}
                </Button>
              </div>
              <div className="output__button">
                <Button
                  width="fullWidth"
                  hierarchy="primary"
                  onClick={handleContinueBtn}
                >
                  {t('continue')}
                </Button>
              </div>
              <p className="output__p">
                {t(`output.${babyOrChild}.outputp1`)}
                <br />
                <br />
                {t(`output.${babyOrChild}.outputp2`)}
              </p>
            </div>
          </div>
          <ColoredBar />
        </div>
      )}
    </>
  );
}

export default Output;
